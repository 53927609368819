<template>
  <van-form @submit="onSubmit">
    <van-field
      v-model="formData.GoodsName"
      name="GoodsName"
      label="作品名称"
      placeholder="请输入作品名称"
      :rules="[{ required: true, message: '' }]"
    />

    <van-field
      readonly
      clickable
      name="CategoryPropertyName"
      v-model="formData.CategoryPropertyName"
      label="作品分类"
      placeholder="请选择作品分类"
      @click="showCategory = true"
      right-icon="arrow"
      :rules="[{ required: true, message: '' }]"
    />
    <van-popup v-model="showCategory" position="bottom">
      <van-picker
        show-toolbar
        :columns="categoryList"
        @confirm="onselectCategory"
        value-key="Name"
        @cancel="showCategory = false"
      />
    </van-popup>

    <van-field
      readonly
      clickable
      v-model="formData.ArtYearName"
      name="ArtYearName"
      label="创作年代"
      placeholder="请选择创作年代"
      @click="showArtYear = true"
      right-icon="arrow"
    />
    <van-popup v-model="showArtYear" position="bottom">
      <van-picker
        show-toolbar
        :columns="artYearList"
        value-key="Name"
        @confirm="onselectArtYear"
        @cancel="showEducation = false"
      />
    </van-popup>

    <van-field
      v-model="formData.ArtSizeName"
      name="ArtSizeName"
      label="作品尺寸"
      placeholder="请输入作品尺寸"
    />

    <van-field
      readonly
      clickable
      v-model="formData.ArtMaterial"
      name="ArtMaterial"
      label="材质"
      placeholder="请选择材质"
      @click="showArtMaterial = true"
      right-icon="arrow"
    />
    <van-popup v-model="showArtMaterial" position="bottom">
      <van-picker
        show-toolbar
        :columns="artMaterialList"
        value-key="Name"
        @confirm="onselectMaterial"
        @cancel="showArtMaterial = false"
      />
    </van-popup>

    <van-field
      readonly
      clickable
      v-model="formData.ArtTheme"
      name="ArtTheme"
      label="题材"
      placeholder="请选择题材"
      @click="showArtTheme = true"
      right-icon="arrow"
    />
    <van-popup v-model="showArtTheme" position="bottom">
      <van-picker
        show-toolbar
        :columns="artThemeList"
        value-key="Name"
        @confirm="onselectTheme"
        @cancel="showArtTheme = false"
      />
    </van-popup>

    <van-field
      readonly
      clickable
      v-model="formData.ArtSkill"
      name="ArtSkill"
      label="技法"
      placeholder="请选择技法"
      @click="showArtSkill = true"
      right-icon="arrow"
    />
    <van-popup v-model="showArtSkill" position="bottom">
      <van-picker
        show-toolbar
        :columns="artSkillList"
        value-key="Name"
        @confirm="onselectSkill"
        @cancel="showArtSkill = false"
      />
    </van-popup>

    <van-field
      readonly
      clickable
      :value="formData.ArtShape"
      name="ArtShape"
      label="形制"
      placeholder="请选择形制"
      @click="showArtShape = true"
      right-icon="arrow"
    />
    <van-popup v-model="showArtShape" position="bottom">
      <van-picker
        show-toolbar
        :columns="artShapeList"
        value-key="Name"
        @confirm="onselectShape"
        @cancel="showArtShape = false"
      />
    </van-popup>

    <van-field
      v-model="formData.WorkSeries"
      name="WorkSeries"
      label="作品系列"
      placeholder="请输入作品系列"
    />

    <van-field v-model="formData.DefaultPrice" type="number" label="售出价格" />
    <van-field
      v-model="formData.Introduce"
      rows="2"
      autosize
      label="作品简介"
      type="textarea"
      maxlength="500"
      placeholder="请输入作品简介"
      show-word-limit
    />

    <van-field name="uploader" label="作品主图">
      <template #input>
        <van-uploader
          v-model="CoverImgFileList"
          :max-count="1"
          :after-read="afterRead"
          @delete="afterdelete"
          name="coverImg"
        />
      </template>
    </van-field>

    <van-field name="uploader" label="作品图集">
      <template #input>
        <van-uploader
          v-model="imgFileList"
          :after-read="afterRead"
          multiple
          upload-text="添加图片"
          @delete="afterdelete"
          name="Images"
        />
      </template>
    </van-field>
    <div style="margin: 16px">
      <van-button round block type="info" native-type="submit">提交</van-button>
    </div>
  </van-form>
</template>
<script>
import { areaList } from "@vant/area-data";
import {
  queryArtSize,
  queryArtProperty,
  queryArtYear,
  queryArtMaterial,
  queryArtTheme,
  queryArtSkill,
  queryArtShape,
  artWorkAdd,
  queryArtWorkDetail,
} from "@/api/art";
import { imagesUploadPic } from "@/api/api";
import { Dialog } from "vant";
export default {
  data () {
    return {
      areaList,
      username: "",
      password: "",
      value: "",
      showArtSize: false,
      showArtYear: false,
      showCategory: false,
      showArea: false,
      showArtMaterial: false,
      showArtShape: false,
      showArtSkill: false,
      showArtTheme: false,
      minDate: new Date(1921, 0, 1),
      defaultDate: new Date(1970, 0, 1),
      maxDate: new Date(),
      artSizeList: [],
      artYearList: [],
      categoryList: [],
      artThemeList: [],
      artSkillList: [],
      artShapeList: [],
      artMaterialList: [],
      formData: {
        CategoryProperty: "",
        CategoryPropertyName: "",
        ArtYearId: "",
        ArtYearName: "",
        ArtSizeId: "",
        ArtSizeName: "",
        ArtShape: "",
        ArtSkill: "",
        ArtMaterial: "",
        ArtTheme: "",
        Introduce: "",
        CoverImg: "",
        Images: "",
        SupplierId: "",
        DefaultPrice: 0.0,
      },
      imgFileList: [],
      imgList: [],
      CoverImgFileList: [],
      CoverImgList: [],
      serverUrl: "",
      uploadName: "",
      route: {},
    };
  },
  created () {
    this.init();
  },
  mounted () {
    this.route = this.$route.query;
    if (this.route.id) {
      this.queryArtWorkDetail();
    }
  },
  methods: {
    async init () {
      // this.getQueryArtProperty(0);
      this.getQueryArtProperty(2);
      this.queryArtSize();
      this.queryArtYear();
      this.queryArtMaterial();
      this.queryArtTheme();
      this.queryArtSkill();
      this.queryArtShape();
    },
    onselectArtSize (value) {
      this.formData.ArtSizeId = value.Id;
      this.formData.ArtSizeName = value.Name;
      this.showArtSize = false;
    },
    onselectArtYear (value) {
      this.formData.ArtYearId = value.Id;
      this.formData.ArtYearName = value.Name;
      this.showArtYear = false;
    },
    onselectCategory (value) {
      this.formData.CategoryProperty = value.Id;
      this.formData.CategoryPropertyName = value.Name;
      this.showCategory = false;
    },
    onselectMaterial (value) {
      this.formData.ArtMaterial = value.Name;
      this.showArtMaterial = false;
    },
    onselectTheme (value) {
      this.formData.ArtTheme = value.Name;
      this.showArtTheme = false;
    },
    onselectSkill (value) {
      this.formData.ArtSkill = value.Name;
      this.showArtSkill = false;
    },
    onselectShape (value) {
      this.formData.ArtShape = value.Name;
      this.showArtShape = false;
    },
    async onSubmit (values) {
      this.formData.CoverImg = this.CoverImgList.join(",");
      this.formData.Images = this.imgList.join(",");
      this.formData.SupplierId =
        this.formData.Id > 0 ? this.formData.SupplierId : this.route.artistId;
      console.log(this.formData);

      const res = await artWorkAdd(this.formData);

      if (res.success == true) {
        Dialog.alert({
          message: "作品发布成功",
          theme: "round-button",
          confirmButtonColor: "#ee0a24",
        }).then(() => {
          let url =
            "/H5/art/artistsDetails?id=" +
            res.response +
            "&artUserId=" +
            this.route.UserID;
          this.$router.push(url);
        });
      } else {
        this.$message.error("作品发布失败，请重试");
      }
    },

    formatDate (date) {
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
    },
    async queryArtWorkDetail () {
      const res = await queryArtWorkDetail(
        "?id=" + this.route.id + "&studentId=" + this.route.UserID
      );

      if (res.data.response != null) {
        this.formData = res.data.response.ArtDetail;
        this.formData.Introduce = this.formData.Introduce.replace(/<[^>]+>/g, '')
        this.CoverImgList.push(res.data.response.ArtDetail.CoverImg);
        this.CoverImgFileList.push({
          url: res.data.response.ArtDetail.CoverImg,
        });
        var imgages = res.data.response.ArtDetail.Images.split(",");
        // console.log(imgages);
        for (let index = 0; index < imgages.length; index++) {
          this.imgList.push(imgages[index]);
          this.imgFileList.push({ url: imgages[index] });
        }
        // console.log(this.imgFileList);
      }
    },
    async getQueryArtProperty (category) {
      let parm = "?category=" + category;
      // console.log(parm, '')
      const res = await queryArtProperty(parm);
      if (res.success == true) {
        this.categoryList = res.response;
      }
    },
    async queryArtSize () {
      const res = await queryArtSize();
      if (res.success == true) {
        this.artSizeList = res.response;
      }
    },
    async queryArtYear () {
      const res = await queryArtYear();
      if (res.success == true) {
        this.artYearList = res.response;
      }
    },
    async queryArtMaterial () {
      const res = await queryArtMaterial();
      if (res.success == true) {
        this.artMaterialList = res.response;
      }
    },
    async queryArtTheme () {
      const res = await queryArtTheme();
      if (res.success == true) {
        this.artThemeList = res.response;
      }
    },
    async queryArtSkill () {
      const res = await queryArtSkill();
      if (res.success == true) {
        this.artSkillList = res.response;
      }
    },
    async queryArtShape () {
      const res = await queryArtShape();
      if (res.success == true) {
        this.artShapeList = res.response;
      }
    },
    afterRead (file, name) {
      console.log(file, name, '')
      file.file.uploadName = name.name;

      this.postImagesUploadPic(file.file);
    },
    // 上传文件
    async postImagesUploadPic (file) {
      const formData = new FormData();
      let newFile = await this.common.compressImg(file); //图片压缩
      formData.append("file", newFile);
      formData.append("progame", "ArtistWork");
      const res = await imagesUploadPic(formData);
      if (res.success == true) {
        let url = this.GLOBAL.hostUrl + res.response;
        if (file.uploadName == "coverImg") {
          this.CoverImgList.push(url);
        } else {
          this.imgList.push(url);
        }
        file.serverUrl = url;

      } else {
        this.$message.error(res.msg);
      }
    },
    afterdelete (file) {
      console.log(file);
      console.log(this.imgList);
      //删除数组中的删除元素
      if (file.file == null) {
        var index = this.imgList.indexOf(file.url);
        console.log(index);
        if (index != -1) {
          this.imgList.splice(index, 1);
        } else {
          this.CoverImgList.splice(index, 1);
        }
      } else {

        if (file.file.uploadName == "coverImg") {
          this.CoverImgList.splice(
            this.CoverImgList.indexOf(file.file.serverUrl),
            1
          );
        } else {
          this.imgList.splice(this.imgList.indexOf(file.file.serverUrl), 1);
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
</style>